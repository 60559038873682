.float-insta {
  position: fixed;
  width: 60px;
  height: 60px;
  background-color: #D43089;
  bottom: 40px;
  right: 120px;
  color: #fff;
  border-radius: 40%;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
  z-index: 4;
}

.icon-instagram {
  width: 60px;
  height: 60px;
}