
/*tratamientos*/

.tratamientosMain{
  background-color: #fff;
  
}

/* .contenedor-tratamientos {

} */
