.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* .capa {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #1c1c1d;
  opacity: 0.5;
  mix-blend-mode: overlay;
} */

.tituloHome {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 6%;
  color: white;
  font-size: clamp(1rem, 3vw, 4rem);
  @media (width < 768px) {
    top: 65%;
  }
}

.contenedorMain {
  width: 100vw;
}

.imagenHome {
  position: absolute;
  z-index: 2;
  top: 60%;
  left: 5%;
  max-width: 1000px;
  min-width: 320px;
  width: 50%;
  @media (width < 768px) {
    inset: 50% 0% 0% 0%;
    margin: auto;
  }
}

.parrafoHome {
  position: absolute;
  z-index: 2;
  top: 80%;
  left: 20%;
  color: white;
  font-size: clamp(1rem, 2.5vw, 4rem);
  font-family: 'Baskervville', serif;
  font-weight: 400;
  @media (width < 768px) {
    top: 80%;
    left: 50%;
    transform: translate(-50%);
  }
}

