
.bodyCursos{
  text-align: center;
}

.imagen-curso {
  width: 500px;
  padding-top: 40px;
  @media (max-width: 768px) {
    width: 300px;
  }
}

.texto-cursos {
  position: relative;
  top: 20%;
  font-family: 'Italiana', sans-serif;
  color: burlywood;
  @media (max-width: 768px) {
    font-size: 1rem;
  }
}

