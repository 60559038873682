
.contenedorContacto{
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
}
.formulario{
  background-color: rgb(212, 248, 195);
  border-radius: 10px;
  padding: 3em 2em;
  box-shadow: 0 5px 10px -5px rgb(0 0 0 / 30%);
  text-align: center;
}
.formularioTitulo{
  font-size: 2rem;
  margin-bottom: .5em;
}
.formularioParrafo{
  font-weight: 300;
}
.formularioContenido{
  margin-top: 3em;
  display: grid;
  gap: 2.5em;
}
.formularioGrupo{
  position: relative;
  --color: #5757577e;

}
.formularioEntrada{
  width: 100%;
  background: none;
  color: #706c6c;
  font-size: 1rem;
  padding: .6em .3em;
  border: none;
  outline: none;
  border-bottom: 1px solid var(--color);
}
.formularioEntrada:not(:placeholder-shown){
  color: #4d4646;
}
.formularioEntrada:focus + .formularioEtiqueta,
.formularioEntrada:not( :placeholder-shown ) + .formularioEtiqueta{
  transform: translateY(-12px) scale(.7);
  transform-origin: left top;
  color: #30be94;
}
.formularioEntradaTexto{
  resize: none;
  padding: 1.8em .5em;
}
.formularioEtiqueta{
  color: var(--color);
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 5px;
  transform: translateY(10px);
  transition: .5s, color .3s;
}
.formularioEnviar{
  background: #30be94;
  color: white;
  font-weight: 300;
  font-size: 1rem;
  padding: .8em 0;
  border: none;
  border-radius: .5em;
  cursor: pointer;
}
.formularioLinea{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #30be94;
  transform: scale(0);
  transform: left bottom;
  transition: transform .4s;
}
.formularioEntrada:focus + .formularioLinea,
.formularioEntrada:not( :placeholder-shown ) ~ .formularioLinea{
  transform: scale(1);
}