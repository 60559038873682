body {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@import url('https://fonts.googleapis.com/css2?family=Baskervville:ital@0;1&family=Italiana&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ms+Madi&family=Raleway:wght@300;700&display=swap');

