

.card{
  display: flex;
  flex-flow: column;
  border-radius: 10px;
  box-shadow: 0 0 0.1em rgba(0, 0, 0, 0.3);
  background-color: white;
  transition-property: box-shadow;
  transition-duration: 0.3s;
  margin: 10px 0px;
}


.card:hover{
  box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.3);
}

.card-titulo {
  padding: 0.8em;
  font-family: 'Raleway';
}

.card-texto{
  border-top: 1px solid rgb(230, 230, 230);
  padding: 1em;
  display: flex;
  flex-flow: column;
  font-family: 'Baskervville';
}

.btn-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: auto;
}

.btn-container button {
  font-size: 0.92rem;
  font-weight: 600;
  color: #fff;
  background: linear-gradient(90deg, #f8ece5 0%, #f1d1c7 100%);
  padding: 0.5rem 2rem;
  border: none;
  border-radius: 0.3rem;
  cursor: pointer;
}

.preserve-line-breaks {
  white-space: pre-line;
}
