.nav-container {
  position: sticky;
  z-index: 3;
  top: 0;
}


.navbar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 10vh;
}

.navbar-home {
  background-color: transparent;
}

.navbar-pages {
  background-color: #f1dbc7;
}

.navbar-logo {
  color: #fff;
  cursor: pointer;
  font-family: "Italiana", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 3rem;
}

.opcion {
  color: #fff;
  text-decoration: none;
  list-style-type: none;
  cursor: pointer;
  align-self: center;
  font-family: 'Baskervville';
  font-size: 1.5rem;
  @media (max-width: 768px) {
    display: block;
    color: white;
    padding-top: 1.5rem;
  }
}

.cart {
  color: #fff;
  width: 24px;
  height: 24px;
  @media (width < 768px) {
    padding: 1.5rem;
  }
}

.link-logo {
  text-decoration: none;
}

.burger {
  @media (min-width: 768px){
    display: none;
  }
}

.responsivo {
  position: absolute;
  top: -1000px;
  left: -1000px;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  transition: all .5s ease;
  @media (min-width:768px){
    position: initial;
    margin: 0;
  }
}

.responsivo.active {
  @media (max-width: 768px) {
    width: 100%;
    display: block;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    top: 100%;
    left: 0;
    right: 0;
    text-align: center;
  }
}

.initial {
  position: absolute;
  background-color: #f1dbc7;
  top: -1000px;
  left: -1000px;
  transition: all .6s ease;
  @media (max-width: 768px) {
    &.active {
      border-radius: 0 0 80% 0;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      z-index: -1;
    }
  }
}
